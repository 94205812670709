import React, { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import ThankYou from "../Contactus/ThankYou";
import ReCAPTCHA from "react-google-recaptcha";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { ClientURL, ServerURL } from "../../urls";
import {
  Formik,
  Form,
  Field,
  ErrorMessage as FormikErrorMessage,
} from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumber } from "awesome-phonenumber";

const CommonContactForm = ({
  pageId = "defaultPageId",
  handleCancel,
}) => {
  const location = useLocation();
  const pageURL = location.pathname;
  const [emailServiceMessage, setEmailServiceMessage] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(false);
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneTouched, setPhoneTouched] = useState(false);

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleVerificationSuccess = (token, ekey) => {
    if(token) {
      setRecaptchaValue(true)
    }
  }

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
  };
  const [phoneValue, setPhoneValue] = useState(initialValues.phone);

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .matches(/^[a-zA-Z]+$/, "You should enter a valid name")
      .required("The First Name field is required."),
    email: Yup.string()
      .email("Invalid email address")
      .required("The Work Email field is required.")
      .test(
        "is-valid-email",
        "Invalid email address",
        (value) => value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
      ),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "You should enter a valid phone number")
      .required("Phone number is required")
      .test("is-valid-phone", "", (value) => {
        if (!value) return false;
        const normalizedPhone = value.startsWith("+") ? value : `+${value}`;
        const phonenumber = parsePhoneNumber(normalizedPhone);
        return phonenumber.valid;
      }),
    message: Yup.string(),
  });

  const handleSubmit = async (values, { resetForm }) => {
    if (!recaptchaValue) {
      return;
    }

    setIsSubmitting(true);
    const hubspotPortalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID;
    const hubspotFormId = process.env.REACT_APP_HUBSPOT_FORM_ID;

    // Format the phone number to include the "+" sign
    const formattedPhone = values.phone.startsWith("+")
      ? values.phone
      : `+${values.phone}`;

    // Create a new values object with the formatted phone number
    const formattedValues = {
      ...values,
      phone: formattedPhone,
    };
    const emailServiceValues = {
      ...formattedValues,
      pageid: pageId,
      ClientURL: ClientURL,
      pagename: pageURL,
    };

    const fields = Object.keys(formattedValues).map((key) => ({
      name: key,
      value: formattedValues[key],
    }));

    try {
      // Submit form data to HubSpot
      const hubspotResponse = await axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${hubspotFormId}`,
        {
          fields,
          context: {
            pageUri: window.location.href,
            pageName: document.title,
          },
        }
      );

      // Make an API request to your backend with the form data
      const emailServiceResponse = await fetch(`${ServerURL}/email-service`, {
        method: "POST",
        body: JSON.stringify(emailServiceValues),
        headers: {
          "Content-Type": "application/json",
        },
        // credentials: 'include', // Uncomment if you need authentication to access APIs
      });
      if (emailServiceResponse.ok) {
        setEmailServiceMessage("Email service executed successfully.");
        setShowThankYouModal(true);
        setPhoneValue("");
        resetForm();
        setRecaptchaValue(""); // Reset reCAPTCHA
        setPhoneTouched(false);
      } else {
        throw new Error("Failed to execute email service");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false); // Reset submission state
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <div className="letstalk_rht_forms_main lets_talk_forms_top">
              <div className="name-flex">
                <div className="mb-3 letstalk_rht_forms_main">
                  <label className="form-label">Name *</label>
                  <Field
                    type="text"
                    name="firstname"
                    placeholder="First Name"
                    className="form-control"
                  />
                  <FormikErrorMessage
                    name="firstname"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="mb-3 letstalk_rht_forms_main">
                  <Field
                    type="text"
                    name="lastname"
                    placeholder="Last Name"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="mb-3 letstalk_rht_forms_main">
                <label className="form-label">Work Email *</label>
                <Field
                  type="email"
                  name="email"
                  placeholder="Enter your work email here"
                  className="form-control"
                />
                <FormikErrorMessage
                  name="email"
                  component="div"
                  className="error"
                />
              </div>
              <div className="mb-3 letstalk_rht_forms_main phone-field">
                <label className="form-label phone-label">Phone Number *</label>
                <PhoneInput
                  country={"us"}
                  value={phoneValue}
                  onChange={(phone) => {
                    setPhoneValue(phone);
                    setFieldValue("phone", phone);
                  }}
                  className="phone-input"
                  placeholder="Enter phone number here"
                  onBlur={() => setPhoneTouched(true)}
                  enableSearch={true}
                  isValid={(value, country) => {
                    if (!phoneTouched) {
                      return true;
                    }
                    const normalizedPhone = value.startsWith("+")
                      ? value
                      : `+${value}`;

                    const phonenumber = parsePhoneNumber(normalizedPhone);
                    return phonenumber.valid
                        ? true
                        : `Invalid phone number format for ${country.name}`;
                  }}
                />
                <FormikErrorMessage
                  name="phone"
                  component="div"
                  className="error"
                />
              </div>
              <div className="mb-3 letstalk_rht_forms_main">
                <label className="form-label">Message</label>
                <Field
                  as="textarea"
                  name="message"
                  rows={3}
                  className="form-control"
                  placeholder="I'm intersted to know more about your services. Please feel free to reach out."
                />
                <FormikErrorMessage
                  name="message"
                  component="div"
                  className="error"
                />
              </div>
              <div className="d-flex justify-content-between align-items-center captchaSection">
                <div className="captha_main">
                  {/* <ReCAPTCHA
                    sitekey="6Lf4c1ooAAAAAHwZEpZKPe71__ne_LCiBxp58OUC"
                    onChange={handleRecaptchaChange}
                  /> */}
                  <HCaptcha
                    sitekey="98d6ce34-346a-4d68-9b94-c132b1c21b8e"
                    // onChange={handleRecaptchaChange}
                    onVerify={(token, ekey) =>
                      handleVerificationSuccess(token, ekey)
                    }
                  />
                  <span className="capth_msg">
                    {(recaptchaValue === false) ? "Please complete the reCAPTCHA" : ""}
                  </span>
                </div>
                <div className="d-flex justify-content-center letstalk_sendbtn_div">
                  <button
                    type="submit"
                    className="pull-right"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {showThankYouModal && (
        <ThankYou
          showThankYouModal={showThankYouModal}
          setShowThankYouModal={setShowThankYouModal}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default CommonContactForm;
