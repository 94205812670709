import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ServerURL } from "../../urls";
import { Spinner } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Footer = () => {
  const [footerMenusDataset, setFooterMenusDataset] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const mainMenu = `${ServerURL}/globals/footerMenu/`;
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const footerMenusResponse = await fetch(mainMenu, {
          credentials: "include", // authentication to access APIs
          // "mode": "cors",
        });
        const footerMenusJson = await footerMenusResponse.json();
        setFooterMenusDataset(footerMenusJson);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="loader-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          {footerMenusDataset?.quickLinks && (
            <section className="footer_section">
              <footer>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 footer_div">
                      <h4>QUICK LINKS</h4>
                      {footerMenusDataset?.quickLinks?.map((menus, index) => (
                          <a key={index} href={`${menus?.link.url}/`}>
                            {" "}
                            {menus?.label}
                          </a>
                        ))}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 footer_div foot_tab_section">
                      <div className="foot_services_main">
                        <h4>SERVICES</h4>
                        {footerMenusDataset?.servicesLink?.map(
                          (menus, index) => (
                            <a key={index} href={`/${menus?.link.url}/`}>
                              {" "}
                              {menus?.label}
                            </a>
                          )
                        )}
                      </div>
                      <div className="footer_sub">
                        <h4>INDUSTRIES</h4>
                        {footerMenusDataset?.industriesLink?.map(
                          (menus, index) => (
                            <a key={index} href={`/${menus?.link.url}/`}>
                              {" "}
                              {menus?.label}
                            </a>
                          )
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 footer_div">
                      <h4>HEADQUARTERS</h4>
                      {footerMenusDataset?.headquarters?.map((item, index) => (
                        <div key={index}>
                          <span className="head_address_span">
                            {item.address}
                          </span>
                          <span className="head_address_span">
                            {item.address2}
                          </span>
                          <div className="footer_sub foot_desk">
                            {item?.contactNumber?.map((dataset, index) => (
                              <a key={index} href={`tel:+${dataset?.number}`}>
                                <i
                                  className={`${dataset?.numberPngText}`}
                                  aria-hidden="true"
                                >
                                  <LazyLoadImage
                                    className="img-fluid foot_icons"
                                    src={dataset?.icon?.url}
                                    alt="phonenumber"
                                  />
                                  <span>{dataset?.number}</span>{" "}
                                </i>
                              </a>
                            ))}
                            {item?.emailAddress?.map((dataset, index) => (
                              <a key={index} href={`mailto:${dataset?.email}`}>
                                {" "}
                                <i
                                  className={`${dataset?.emailPngText}`}
                                  aria-hidden="true"
                                >
                                  <LazyLoadImage
                                    className="img-fluid foot_icons"
                                    src={dataset?.icon?.url}
                                    alt="mail"
                                  />
                                  <span>{dataset?.email}</span>
                                </i>
                              </a>
                            ))}
                            <div className="footer_sub footer_social">
                              <h4>FOLLOW US</h4>
                              {footerMenusDataset?.followUs[0]?.linkedin?.map(
                                (item, index) => (
                                  <a
                                    key={index}
                                    href={`${item?.linkedinURL}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <LazyLoadImage
                                      className="img-fluid"
                                      src={item?.icon?.url}
                                      alt="facebook"
                                    />
                                  </a>
                                )
                              )}
                              {footerMenusDataset?.followUs[0]?.twitter?.map(
                                (item, index) => (
                                  <a
                                    key={index}
                                    href={`${item?.twitterURL}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <LazyLoadImage
                                      className="img-fluid"
                                      src={item?.icon?.url}
                                      alt="facebook"
                                    />
                                  </a>
                                )
                              )}
                              {footerMenusDataset?.followUs[0]?.facebook?.map(
                                (item, index) => (
                                  <a
                                    key={index}
                                    href={`${item?.facebookURL}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <LazyLoadImage
                                      className="img-fluid"
                                      src={item?.icon?.url}
                                      alt="facebook"
                                    />
                                  </a>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 footer_div">
                      <div className="usa_section">
                        <h4>USA</h4>
                        {footerMenusDataset?.USA?.map((dataset, dataIndex) => (
                          <span key={dataIndex}>{dataset?.address1}</span>
                        ))}
                      </div>
                      <h4>INDIA</h4>
                      {footerMenusDataset?.india?.map((dataset, dataIndex) => (
                        <span key={dataIndex}>{dataset?.address}</span>
                      ))}
                      {footerMenusDataset?.headquarters?.map((item, index) => (
                        <div className="footer_sub foot_sub_mobile" key={index}>
                          {item?.contactNumber?.map(
                            (dataset, dataIndex) => (
                              <a
                                key={dataIndex}
                                href={`tel:+${dataset?.number}`}
                              >
                                <i
                                  className={`${dataset?.numberPngText}`}
                                  aria-hidden="true"
                                >
                                  <LazyLoadImage
                                    className="img-fluid foot_icons"
                                    src={dataset?.icon?.url}
                                    alt="phonenumber"
                                  />
                                  <span>{dataset?.number}</span>{" "}
                                </i>
                              </a>
                            )
                          )}
                          {item?.emailAddress?.map((dataset, index) => (
                            <a key={index} href={`mailto:${dataset?.email}`}>
                              {" "}
                              <i
                                className={`${dataset?.emailPngText}`}
                                aria-hidden="true"
                              >
                                <LazyLoadImage
                                  className="img-fluid foot_icons"
                                  src={dataset?.icon?.url}
                                  alt="mail"
                                />
                                <span>{dataset?.email}</span>
                              </i>
                            </a>
                          ))}
                          <div className="footer_sub footer_social">
                            <h4>FOLLOW US</h4>
                            {footerMenusDataset?.followUs[0]?.linkedin?.map(
                              (item, index) => (
                                <a
                                  key={index}
                                  href={`${item?.linkedinURL}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <LazyLoadImage
                                    className="img-fluid"
                                    src={item?.icon?.url}
                                    alt="facebook"
                                  />
                                </a>
                              )
                            )}
                            {footerMenusDataset?.followUs[0]?.twitter?.map(
                              (item, index) => (
                                <a
                                  key={index}
                                  href={`${item?.twitterURL}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <LazyLoadImage
                                    className="img-fluid"
                                    src={item?.icon?.url}
                                    alt="facebook"
                                  />
                                </a>
                              )
                            )}
                            {footerMenusDataset?.followUs[0]?.facebook?.map(
                              (item, index) => (
                                <a
                                  key={index}
                                  href={`${item?.facebookURL}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <LazyLoadImage
                                    className="img-fluid"
                                    src={item?.icon?.url}
                                    alt="facebook"
                                  />
                                </a>
                              )
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </footer>
              <section className="copyright_section">
                <div className="container">
                  <div className="row">
                    <div className="copyrights">
                      <span>
                        {footerMenusDataset?.copyright[0]?.copyrightContent}
                      </span>
                      {footerMenusDataset?.copyright[0]?.copyrightLinks?.map(
                        (item, index) => (
                          <span key={index}>
                            <Link to={`${item?.link?.url}/`}>{item?.label}</Link>{" "}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </section>
          )}
        </>
      )}
    </>
  );
};

export default Footer;
