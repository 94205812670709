import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarMenu from "./Components/Layout/NavbarMenu";
import Footer from "./Components/Layout/Footer";
import "./App.css";
import "./Common.css";
import "./mediaquery.css";
import AppRoutes from "./AppRoutes";

function App() {
  const [scrollActive, setScrollActive] = useState(false);
  const sectionRefs = useRef(null);

  const scrollActiveFun = () => {
    if (window.scrollY >= 100) {
      setScrollActive(true);
    } else {
      setScrollActive(false);
    }
  };

  useEffect(() => {
    scrollActiveFun();
    window.addEventListener("scroll", scrollActiveFun);
    return () => {
      window.removeEventListener("scroll", scrollActiveFun);
    };
  }, []);

  const handleLoadMore = () => {
    sectionRefs.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <NavbarMenu />
      <div ref={sectionRefs}>
        <AppRoutes />
      </div>
      <Footer />
      {scrollActive && (
        <div className="slide_up_arrow_sec">
          <button onClick={handleLoadMore}>
            {" "}
            <img
              className="img-fluid"
              src={"/media-images/up_slide_arrow.png"}
              alt="up_slide_arrow"
            ></img>
          </button>
        </div>
      )}
    </>
  );
}

export default App;
