import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { ServerURL } from "../../urls";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export const RelatedBlogsSection = () => {
  const paramsValue = useParams();

  const [relatedBlogs, setRelatedBlogs] = useState([]);

  useEffect(() => {
    const getRelatedBlogs = async () => {
      const apiUrl = `${ServerURL}/landingPage/recommended-blogs?pageTitle=${paramsValue.id}`;

      const params = {
        pageTitle: paramsValue.id,
      };

      const res = await axios.get(apiUrl);

      setRelatedBlogs(res.data.relatedBlogs);
    };

    getRelatedBlogs();
  }, [paramsValue.id]);

  if (relatedBlogs.length === 0) {
    return null;
  }
  return (
    <section className="related_blogs_section">
      <div className="container">
        <div className="row">
          <div className="related_blogs_head">
            <h4 className="related_blogs_txt">related blogs</h4>
          </div>
          {relatedBlogs.map((item) => (
            <div key={item?.pageTitle} className="col-lg-3 col-md-6 col-sm-12">
              <Link
                to={`/blogs/${item?.pageTitle}/`}
                className="related_blog_link"
              >
                <div className="related_blog_card_body">
                  <div className="related_blog_img">
                    <LazyLoadImage
                      className="img-fluid"
                      src={item?.thumbNail}
                      alt=""
                    />
                  </div>
                  <div className="related_blog_content">
                    <h4>{item?.tags[0]?.value}</h4>
                    <div className="d-flex related_blog_btm_sec">
                      <span>
                        {item?.data[2]?.textvalue?.match(
                          /(\d+ min read)/
                        )?.[1] ?? "5 min read"}
                      </span>
                      <span>
                        {moment(
                          item?.data[2]?.textvalue?.match(
                            /Published (\w+ \d{1,2}, \d{4})/
                          )?.[1] ?? ""
                        ).isValid()
                          ? moment(
                              item.data[2].textvalue.match(
                                /Published (\w+ \d{1,2}, \d{4})/
                              )[1]
                            ).format("MMMM D, YYYY")
                          : "Invalid date"}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
