//@ts-nocheck

import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { Some } from "../../utils/Some";
import { Helmet } from "react-helmet";
import { generateReactElements } from "custom-re-usable-components/dist/index";
import { ServerURL } from "../../urls";
import LetsTalk from "../Home/LetsTalk";
import ConversationSection from "../Contactus/ConversationSection";

export const Home = () => {
  const [page, setPage] = useState();
  const [content, setContent] = useState();
  const [tags, setTags] = useState();
  const [tracking, setTracking] = useState();
  const [theme, setTheme] = useState("");
  const url = window.location.href;
  const id = url.substring(url.lastIndexOf("/") + 1);
  const [cssinputValue, setCSSInputValue] = useState();
  const [collectionName, setCollectionName] = useState("");
  const [letsTalk, setLetsTalk] = useState(false);
  const [letsTalkVisible, setLetsTalkVisible] = useState(false);
  const [scripts, setScripts] = useState("");

  const handleLetsTalk = () => {
    setLetsTalkVisible(true);
  };
  const handleCancel = () => {
    setLetsTalkVisible(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id !== undefined) {
          const apiUrl = `${ServerURL}/landingPage/page`;

          const res = await axios.get(apiUrl, {
            params: {
              pageTitle: "home",
            },
          });
          const response = res.data.docs[0];

          const parsedJSON = JSON.parse(response.metaData);

          setPage(parsedJSON);
          setTags(response.tags);
          setTracking(response.tracking);
          setCSSInputValue(response.styles);
          setScripts(response.scripts);
          setCollectionName(response.collectionName);
          setContent(response.data.data ?? []);
          setTheme(response.theme);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const loadedScripts = [];
    const loadedStylesheets = [];

    const loadScript = (src, attributes = {}) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        Object.entries(attributes).forEach(([key, value]) =>
          script.setAttribute(key, value)
        );
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
        loadedScripts.push(script);
      });
    };

    const loadStylesheet = (href) => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = href;
      document.head.appendChild(link);
      loadedStylesheets.push(link);
    };

    const loadScripts = async () => {
      try {
        await loadScript("https://code.jquery.com/jquery-3.6.0.min.js");
        await loadScript(
          "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"
        );
        await loadScript(
          "https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"
        );

        loadStylesheet(
          "https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css"
        );
        loadStylesheet(
          "https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.min.css"
        );

        if (scripts) {
          const customScript = document.createElement("script");
          customScript.text = scripts;
          document.body.appendChild(customScript);
        }
      } catch (error) {
        console.error("Error loading scripts:", error);
      }
    };

    if (scripts) {
      loadScripts();
    }

    return () => {
      // Cleanup all loaded scripts
      loadedScripts.forEach((script) => script.remove());

      // Cleanup all loaded stylesheets
      loadedStylesheets.forEach((stylesheet) => stylesheet.remove());
    };
  }, [scripts]);

  return (
    <>
      {Some(tags) && (
        <Helmet>
          {tags &&
            tags.map((t) =>
              t.label.toLowerCase() === "title" ? (
                <title>{t.value}</title>
              ) : (
                <meta name={t.label} content={t.value} key={t.label} />
              )
            )}
        </Helmet>
      )}

      {tracking &&
        tracking.map((t) => {
          if (t.label === "googleAnalytics") {
            return (
              <Helmet>
                <script
                  async
                  src={`https://www.googletagmanager.com/gtag/js?id=${t.value}`}
                ></script>
                <script>
                  {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${t.value}');
          `}
                </script>
              </Helmet>
            );
          }
          if (t.label === "plausibleAnalytics") {
            return (
              <Helmet>
                <script
                  defer
                  data-domain={`${t.value}`}
                  src="https://plausible.io/js/script.exclusions.local.js"
                ></script>
              </Helmet>
            );
          }
          return null;
        })}

      {/* Do not disturb this styles */}
      <style>{cssinputValue}</style>

      <div
        style={{
          backgroundColor: theme === "light" ? "white" : "black",
        }}
      >
        {Some(page) && Some(content) && generateReactElements(page, content)}
        <ConversationSection />
      </div>
    </>
  );
};
