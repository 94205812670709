//@ts-nocheck

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Some } from "../../utils/Some";
import { Helmet } from "react-helmet";
import { Spinner } from "react-bootstrap";
// import "../styles/light.css";
// import { generateReactElements } from "../utils/generateReactComponents";
import { generateReactElements } from "custom-re-usable-components/dist/index";
import { ServerURL } from "../../urls";
import qs from "qs";
import { useParams, useNavigate } from "react-router-dom";
import { RelatedBlogsSection } from "../Blogs/RelatedBlogsSection";
import ConversationSection from "../Contactus/ConversationSection";

export const ServiceModule = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState();
  const [content, setContent] = useState();
  const [tags, setTags] = useState();
  const [tracking, setTracking] = useState();
  const [theme, setTheme] = useState("");
  const url = window.location.href;
  const id = url.substring(url.lastIndexOf("/") + 1);
  const [cssinputValue, setCSSInputValue] = useState();
  const [collectionName, setCollectionName] = useState("");
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const [scripts, setScripts] = useState("");

  //   let { id } = useParams();
  //   let { subid } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id !== undefined) {
          const updatedID = id.slice(0, -3);

          const query = {
            pageTitle: {
              equals: params.id,
            },

            // This query could be much more complex
            // and QS would handle it beautifully
          };
          const stringifiedQuery = qs.stringify(
            {
              where: query,
            },
            { addQueryPrefix: true }
          );

          const apiUrl = `${ServerURL}/landingPage/page`;
          // let collectionNames = params.id.split("-");
          // let finalCollectionName = "";
          // collectionNames.map((item, index) => {
          //   let tempString = item.charAt(0).toUpperCase() + item.slice(1);
          //   finalCollectionName = finalCollectionName + tempString + " ";
          // });

          const res = await axios.get(apiUrl, {
            params: {
              pageTitle: params.subid,
              //collectionName: finalCollectionName.trim(),
            },
          });
          const response = res.data.docs[0];
          if (!response || response.pageTitle !== params.subid) {
            navigate("/page-not-found/");
            return;
          }
          const parsedJSON = JSON.parse(response.metaData);

          setPage(parsedJSON);
          setTags(response.tags);
          setTracking(response.tracking);
          setScripts(response.scripts);
          setCSSInputValue(response.styles);
          setCollectionName(response.collectionName);
          setContent(response.data ?? []);
          setTheme(response.theme);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [params.id]);

  useEffect(() => {
    const loadedScripts = [];

    const loadScript = (src, attributes = {}) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        Object.entries(attributes).forEach(([key, value]) =>
          script.setAttribute(key, value)
        );
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
        loadedScripts.push(script);
      });
    };

    const loadScripts = async () => {
      try {
        await loadScript("https://code.jquery.com/jquery-3.6.0.min.js");
        await loadScript(
          "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.bundle.min.js"
        );
        await loadScript(
          "https://www.google.com/recaptcha/enterprise.js?onload=onloadCallback&render=explicit",
          { async: "", defer: "" }
        );

        if (scripts) {
          const customScript = document.createElement("script");
          customScript.text = scripts;
          document.body.appendChild(customScript);
          loadedScripts.push(customScript);
        }
      } catch (error) {
        console.error("Error loading scripts:", error);
      }
    };

    if (scripts) {
      loadScripts();
    }

    return () => {
      // Cleanup all loaded scripts
      loadedScripts.forEach((script) => document.body.removeChild(script));
    };
  }, [scripts]);

  if (loading) {
    return (
      <div className="loader-container">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <>
      {Some(tags) && (
        <Helmet>
          {tags &&
            tags.map((t) =>
              t.label.toLowerCase() === "title" ? (
                <title>{t.value}</title>
              ) : (
                <meta name={t.label} content={t.value} key={t.label} />
              )
            )}
        </Helmet>
      )}

      {tracking &&
        tracking.map((t) => {
          if (t.label === "googleAnalytics") {
            return (
              <Helmet>
                <script
                  async
                  src={`https://www.googletagmanager.com/gtag/js?id=${t.value}`}
                ></script>
                <script>
                  {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${t.value}');
          `}
                </script>
              </Helmet>
            );
          }
          if (t.label === "plausibleAnalytics") {
            return (
              <Helmet>
                <script
                  defer
                  data-domain={`${t.value}`}
                  src="https://plausible.io/js/script.exclusions.local.js"
                ></script>
              </Helmet>
            );
          }
          return null;
        })}

      {/* Do not disturb this styles */}
      <style>{cssinputValue}</style>

      <div
        style={{
          backgroundColor: theme === "light" ? "white" : "black",
        }}
      >
        {Some(page) &&
          Some(content) &&
          generateReactElements(page, content, theme)}
        <ConversationSection />

        {Some(collectionName) && collectionName === "Blogs" && (
          <RelatedBlogsSection />
        )}
      </div>
    </>
  );
};
