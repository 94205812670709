import React, { useState, useEffect, Suspense } from "react";
import LetsTalk from "../Home/LetsTalk";
import { ServerURL } from "../../urls";

const ConversationSection = ({ pageId }) => {
  const [letsTalkVisible, setLetsTalkVisible] = useState(false);
  const successStoryPages = [
    "/success-stories/health-analytics/",
    "/success-stories/mobile-payments/",
    "/success-stories/communication-platform/",
    "/success-stories/learning-platform/",
    "/success-stories/online-ordering-platform/",
    "/success-stories/iot-data-solutions/",
    "/success-stories/experiential-learning/",
    "/whitepapers/experiential-learning-platform/",
  ];
  const isSuccessStoryPage = successStoryPages.includes(
    window.location.pathname
  );

  //   const [hubsptMeetingSchedulerLink, setHubsptMeetingSchedulerLink] =
  //     useState("");

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const response = await fetch(`${ServerURL}/HomeNew/`);
  //         const data = await response.json();
  //         setHubsptMeetingSchedulerLink(data.docs);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     };
  //     fetchData();
  //   }, []);

  const handleLetsTalk = () => {
    setLetsTalkVisible(true);
  };

  const handleCancel = () => {
    setLetsTalkVisible(false);
  };

  return (
    <>
      <section className="lets_connectnew_section">
        <div className="container">
          <div className="conversationSection lets_connectnew_rht_sec">
            {isSuccessStoryPage ? (
              <span>Interested in reading more?</span>
            ) : (
              <span>Explore how we can help you </span>
            )}{" "}
            <div className="lets_connectnew_rht_sec">
              <button onClick={handleLetsTalk}>{isSuccessStoryPage ? "GET YOUR COPY" : "SEND A MESSAGE"}</button>
              <a
                // href={hubsptMeetingSchedulerLink[0]?.hubsptMeetingSchedulerLink}
                href="https://meetings.hubspot.com/enlume-s"
                target="_blank"
                className="scheduleCallBtn"
              >
                SCHEDULE A CALL
              </a>
            </div>
          </div>
        </div>
      </section>
      {letsTalkVisible && (
        <Suspense fallback={<div>Loading...</div>}>
          <LetsTalk
            letsTalkVisible={letsTalkVisible}
            handleCancel={handleCancel}
            pageId={pageId}
          />
        </Suspense>
      )}
    </>
  );
};

export default ConversationSection;
