import React, { useEffect, useState, useCallback, useRef } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useLocation } from "react-router-dom";
import logoBlack from "../../images/Enlume_logo_black.png";
import logoWhite from "../../images/logo-orng-white.webp";
import { routes } from "./Routes";
import { ServerURL } from "../../urls";
import Breadcrumb from "../../Breadcrumbs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const NavbarMenu = () => {
  const location = useLocation();
  const [navbar, setNavbar] = useState(false);
  const [navbarLogo, setNavbarLogo] = useState(
    location.pathname === "" ? logoBlack : logoWhite
  );
  const [dropdownStates, setDropdownStates] = useState({});
  const [isMobileView, setIsMobileView] = useState(false);
  const [mainMenuData, setMainMenuData] = useState([]);

  useEffect(() => {
    const mainMenu = `${ServerURL}/globals/headerMenu/`;

    const fetchData = async () => {
      try {
        const mainMenusResponse = await fetch(mainMenu, {
          credentials: "include",
        });
        const mainMenusJson = await mainMenusResponse.json();
        setMainMenuData(mainMenusJson);
      } catch (error) {
        console.error("error", error);
      }
    };
    fetchData();
  }, []);

  const [showToggle, setShowToggle] = useState(false);
  const topRef = useRef(null);
  const toggleRef = useRef(null);

  const changeBackground = () => {
    if (window.scrollY >= 100 || showToggle) {
      setNavbar(true);
      setNavbarLogo(logoBlack);
    } else {
      setNavbar(false);
      setNavbarLogo(logoWhite);
    }
  };

  useEffect(() => {
    if (!isMobile) {
      setNavbarLogo(window.scrollY >= 100 ? logoBlack : logoWhite);
    } else {
      setNavbarLogo(logoBlack);
    }
  }, [isMobile]);

  useEffect(() => {
    setNavbarLogo(location.pathname === "" ? logoBlack : logoWhite);
    if (location.pathname !== "") {
      changeBackground();
      window.addEventListener("scroll", changeBackground);
    }
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, [location.pathname]);

  const is404 = !routes?.some((route) => location?.pathname === route);

  const handleToggle = () => {
    setShowToggle(!showToggle);
    setNavbarLogo(logoBlack);
  };

  const toggleDropdown = (index) => {
    setDropdownStates((prevStates) => {
      const newState = { ...prevStates };
      Object.keys(newState).forEach((key) => {
        if (key !== index && newState[key]) {
          newState[key] = false; // Close other dropdowns
        }
      });
      newState[index] = !newState[index]; // Toggle the selected dropdown
      return newState;
    });
  };


  useEffect(() => {
    if (is404 && isMobileView) {
      setNavbarLogo(logoBlack);
    }
  }, []);

  return (
    <div
      ref={topRef}
      className={location.pathname === "" ? "" : "header_nav pre_navbar"}
    >
      <Navbar
        expand={isMobile ? "false" : "lg"}
        fixed="top"
        className={navbar || is404 || showToggle ? "navbar active" : "navbar"}
        collapseOnSelect
      >
        <Container>
          <Navbar.Brand href="/">
            <img
              className="enlume_logo"
              style={{ color: "black" }}
              src={
                isMobile
                  ? navbarLogo
                  : showToggle
                  ? logoBlack
                  : is404
                  ? logoBlack
                  : navbarLogo
              }
              alt="Enlume Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            id="basicNavbarToggleId"
            aria-controls="basic-navbar-nav"
            ref={toggleRef}
            onClick={handleToggle}
          />

          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            {isMobile ? (
              <Nav className="nav-main">
                {mainMenuData?.headerLinks?.map((item, index) => {
                  if (item?.type === "subMenu" ) {
                    return (
                      <NavDropdown
                        title={
                          <div className="navmb-title">
                            <Nav.Link
                              as={Link}
                              to={`${item?.link?.url}/`}
                              eventKey={`menu-item-${index}`}
                              className="navmb_pad0"
                              onClick={() => {
                                setDropdownStates((prevStates) => {
                                  const newState = {};
                                  Object.keys(prevStates).forEach((key) => {
                                    newState[key] = false;
                                  });
                                  return newState;
                                });
                                setShowToggle(false);
                              }}
                            >
                              {item?.label}
                            </Nav.Link>
                            {item?.subMenu?.blocks?.length !== 0 && (item?.type !== "link") && (
                              <FontAwesomeIcon
                                icon={
                                  dropdownStates[index]
                                    ? faChevronUp
                                    : faChevronDown
                                }
                                onClick={() => toggleDropdown(index)}
                              />
                            )}
                          </div>
                        }
                        id={`nav-dropdown-${index}`}
                        key={`nav-dropdown-${index}`}
                        className="nav-item dropdown"
                        show={dropdownStates[index]}
                      >
                        {item?.subMenu?.blocks?.length !== 0 && item?.type !== "link" &&
                          item?.subMenu?.blocks?.map((block, blockIndex) => (
                            <Nav.Link
                              as={Link}
                              to={`${block?.link?.url}/`}
                              eventKey={`sub-menu-item-${blockIndex}`}
                              className="dropdown-toggle nav-link dropdown_main"
                              onClick={() => {
                                setDropdownStates({
                                  ...dropdownStates,
                                  [index]: false,
                                });
                                setShowToggle(false);
                              }}
                            >
                              {block?.blockName}
                            </Nav.Link>
                          ))}
                      </NavDropdown>
                    );
                  }
                  if (item?.type === "link") {
                    return (
                          <div className="navmb-title">
                            <Nav.Link
                              as={Link}
                              to={`${item?.link?.url}/`}
                              eventKey={`menu-item-${index}`}
                              className={`navmb_pad0 navLink ${item?.label === "Contact Us" ? "contact-us-style" : ""}`}
                              onClick={() => {
                                setDropdownStates((prevStates) => {
                                  const newState = {};
                                  Object.keys(prevStates).forEach((key) => {
                                    newState[key] = false;
                                  });
                                  return newState;
                                });
                                setShowToggle(false);
                              }}
                            >
                              {item?.label}
                            </Nav.Link>
                          </div>
                    )
                  }
                })}
              </Nav>
            ) : (
              <Nav className="nav-main">
                {mainMenuData?.headerLinks?.map((item, index) => {
                  if (item.type === "subMenu") {
                    return (
                      <div
                        id={item?.label?.toLowerCase()}
                        key={item?.label?.toLowerCase()}
                        className="nav-item dropdown"
                      >
                        <NavDropdown.Item
                          key={index}
                          tabIndex={index}
                          as={Link}
                          to={`${item?.link?.url}/`}
                          eventkey={item?.link?.url}
                          className="dropdown-toggle nav-link dropdown_main"
                        >
                          {item?.label}
                        </NavDropdown.Item>
                        <div id={`${item?.label?.toLowerCase()}-sub-menu`}>
                          {item.subMenu.blocks.map((subMenuItem, subIndex) => {
                            return subMenuItem?.link?.type === "custom" ? (
                              <NavDropdown.Item
                                key={subMenuItem?.blockName}
                                tabIndex={subIndex}
                                as={Link}
                                to={`${subMenuItem?.link?.url}/`}
                                eventkey={subMenuItem?.link?.url}
                              >
                                {subMenuItem?.blockName}
                              </NavDropdown.Item>
                            ) : (
                              ""
                            );
                          })}
                        </div>
                      </div>
                    );
                  }

                  if (item.type === "link") {
                    return (
                      <div className="nav-item dropdown" key={index}>
                        <NavDropdown.Item
                          className={`dropdown-toggle nav-link dropdown_main ${item?.label === "Contact Us" ? "contact-us-style" : ""}`}
                          key={index}
                          id={item?.link?.url}
                          tabIndex={index}
                          as={Link}
                          to={`${item?.link?.url}/`}
                          eventkey={item?.link?.url}
                        >
                          {item?.label}
                        </NavDropdown.Item>
                      </div>
                    );
                  }
                })}
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
        <div className="breadcrumb_container">
          <div className="container">
            <Breadcrumb navbar={navbar} is404={is404} showToggle={showToggle} />
          </div>
        </div>
      </Navbar>
    </div>
  );
};

export default NavbarMenu;
