
export const routes = [
    '/',
    "/services/",
    "/services/cloud-enablement/",
    "/services/product-engineering/",
    "/services/data-engineering/",
    "/success-stories/",
    "/aboutus/",
    "/contactus/",
    "/industries/healthcare/",
    "/industries/finance/",
    "/industries/energy/",
    "/industries/retail/",
    "/blogs/",
    "/success-stories/:id/",
    "/success-stories/online-ordering-platform/",
    "/success-stories/mobile-payments/",
    "/success-stories/iot-data-solutions/",
    "/success-stories/communication-platform/",
    "/success-stories/learning-platform/",
    "/success-stories/health-analytics/",
    "/success-stories/experiential-learning/",
    "/services/data-engineering/realtime-analytics/",
    "/services/data-engineering/data-preparation/",
    "/services/data-engineering/bigdata-solutions/",
    "/services/cloud-enablement/managed-services/",
    "/services/cloud-enablement/cloud-real-time-analyticks/",
    "/services/cloud-enablement/real-time-analytics/",
    "/services/cloud-enablement/cloud-nativeapp/",
    "/services/cloud-enablement/cloud-migration/",
    "/services/cloud-enablement/devops-on-cloud/",
    "/services/product-engineering/qa-services/",
    "/services/product-engineering/enterprise-applications/",
    "/services/product-engineering/web-applications/",
    "/services/product-engineering/mobility/",
    "/services/ai-ml-solutions/",
    "/services/ai-ml-solutions/model-development-and-deployment/",
    "/services/ai-ml-solutions/generative-ai/",
    "/services/ai-ml-solutions/ai-automation/",
    "/industries/",
    "/privacy-statement/",
    "/terms-and-conditions/",
    "/innovation/",
    "/innovation/page-builder/",
    "/innovation/v-assist/",
    "/whitepapers/",
    "/whitepapers/experiential-learning-platform/",
    "/privacy-statement/",
    "/terms-and-conditions/"
    // Add your other routes here
    
  ];
  
