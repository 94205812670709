import React, { lazy, Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./Common.css";
import "./mediaquery.css";
import { DynamicRouting } from "./Components/DynamicRouting/DynamicRouting";
import { Home } from "./Components/Home/Home";

const ErrorPage = lazy(() => import("./Components/ErrorPage/ErrorPage"));
const PrivacyStatement = lazy(() => import("./Components/PrivacyAndTerms/PrivacyStatement"));
const TermsAndConditions = lazy(() => import("./Components/PrivacyAndTerms/TermsAndConditions"));

function AppRoutes() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Suspense
      fallback={
        <div className="loader-container">
          <Spinner animation="border" variant="primary" />
        </div>
      }
    >
      <Routes>
        <Route path="/page-not-found/" element={<ErrorPage />} />
        <Route path="*" element={<Navigate to="/page-not-found/" />} />
        {/*TODO: Enable home route */}
        <Route path="/" element={<Home />} />
        <Route path="/:header/:id?/:subid?" element={<DynamicRouting />} />
        <Route path="/privacy-statement/" element={<PrivacyStatement />} />
        <Route path="/terms-and-conditions/" element={<TermsAndConditions />} />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
