import { Navigate, useParams } from "react-router-dom";
import Blogs from "../Blogs/Blogs";
import { PluginPageBuilder } from "../PluginChanges/PluginPageBuilder";
import ErrorPage from "../ErrorPage/ErrorPage";
import { Services } from "../Services/Services";
import { Industries } from "../Industries/Industries";
import { Aboutus } from "../Aboutus/Aboutus";
import { Innovation } from "../Innovation/Innovation";
import { SuccessStories } from "../Success-Stories/Success-Stories";
import { Whitepapers } from "../Whitepapers/Whitepapers";
import { Managedservices } from "../Services/Managedservices";
import { ServiceModule } from "../PluginChanges/ServicesModule";
import { Contactus } from "../Contactus/Contactus";

export const DynamicRouting = () => {
  const { header } = useParams();
  let { id } = useParams();
  let { subid } = useParams();
  const servicesIdList = [
    "product-engineering",
    "cloud-enablement",
    "data-engineering",
    "ai-ml-solutions",
  ];
  const subIdList = [
    "managed-services",
    "cloud-migration",
    "devops-on-cloud",
    "cloud-nativeapp",
    "real-time-analytics",
    "qa-services",
    "enterprise-applications",
    "mobility",
    "web-applications",
    "data-preparation",
    "bigdata-solutions",
    "model-development-and-deployment",
    "generative-ai",
    "ai-automation",
    "retail",
    "realtime-analytics",
  ];

  if (header === "blogs") {
    return id ? <PluginPageBuilder /> : <Blogs />;
  }
  if (
    header === "services" &&
    servicesIdList.includes(id) &&
    subIdList.includes(subid)
  ) {
    return <ServiceModule />;
  }
  if (header === "services") {
    return id ? <PluginPageBuilder /> : <Services />;
  }
  if (header === "industries") {
    return id ? <PluginPageBuilder /> : <Industries />;
  }
  if (header === "aboutus") {
    return id ? <PluginPageBuilder /> : <Aboutus />;
  }
  if (header === "innovation") {
    return id ? <PluginPageBuilder /> : <Innovation />;
  }
  if (header === "success-stories") {
    return id ? <PluginPageBuilder /> : <SuccessStories />;
  }
  if (header === "whitepapers") {
    return id ? <PluginPageBuilder /> : <Whitepapers />;
  }
  if (header === "contactus") {
    return id ? <PluginPageBuilder /> : <Contactus />;
  } else {
    return <Navigate to="/page-not-found/" />;
  }
};
