import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom"; 

const ErrorPage = () => {
  return (
    <div>
      <Helmet>
        <title>Error 404 - page not found</title>
      </Helmet>
    <section className='error_msg_section'>
        <div className='container'>
            <div className="row">
                <div className="error_msg_div">
                    <h1>404</h1>
                    <h3>Sorry, this page cannot be found</h3>
                    <p>Please return to the  <Link to="/">homepage</Link> to see all our available pages.</p>
                    <span>Sorry for the inconvenience!</span>
                </div>
            </div>
        </div>

    </section>
    </div>
  )
}

export default ErrorPage
